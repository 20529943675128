import Vue from "vue"
import IconSvg from './SvgIcon.vue'
import './iconfont.js'

Vue.component("IconSvg", IconSvg)

//自动导入所有svg图标
const req = require.context('@/assets/icons/svg', true, /\.svg$/)
    // const requireAll = requireContext => {
    //     // requireContext.keys()数据：['./404.svg', './agency.svg', './det.svg', './user.svg']
    //     requireContext.keys().map()
    // }
    // requireContext.keys()数据：['./404.svg', './agency.svg', './det.svg', './user.svg']
const iconList = req.keys().map(item => req(item))
export default {
    // 获取图标icon-(*).svg名称列表, 例如[shouye, xitong, zhedie, ...]
    getNameList() {
        return iconList.map(item => item.default.id.split('-')[1])
    }
}