/**
 * 获取uuid
 */
export function getUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
        return (c === 'x' ? (Math.random() * 16 | 0) : ('r&0x3' | '0x8')).toString(16)
    })
}

/**
 * 树形数据转换
 * @param {*} data
 * @param {*} id
 * @param {*} pid
 */
export function treeDataTranslate(data, id = 'id', pid = 'pid') {
    var res = []
    var temp = {}
    for (var i = 0; i < data.length; i++) {
        temp[data[i][id]] = data[i]
    }
    for (var k = 0; k < data.length; k++) {
        if (temp[data[k][pid]] && data[k][id] !== data[k][pid]) {
            if (!temp[data[k][pid]]['children']) {
                temp[data[k][pid]]['children'] = []
            }
            if (!temp[data[k][pid]]['_level']) {
                temp[data[k][pid]]['_level'] = 1
            }
            data[k]['_level'] = temp[data[k][pid]]._level + 1
            temp[data[k][pid]]['children'].push(data[k])
        } else {
            res.push(data[k])
        }
    }
    return res
}

/**
 * 是否有权限
 * @param {*} key
 */
export function isAuth(key) {
    return localStorage.getItem('edb-permissions') || '[]'.indexOf(key) !== -1 || false
}

/**
 * 根据字典值展示不通的el-tag type类型
 */
export function getTagType(value) {
    let data = ['danger', '', 'success', 'info', 'warning', 'success', 'danger', '#', 'info', 'success', '', 'success']
    return data[value]
}

/**
 * 获取缓存中字典
 */
function getAllDicItems() {
    // todo:写死待定
    const dicItems = {
            STATUS: [{
                dicCode: '1',
                dicValue: '正常'

            }, {
                dicCode: '2',
                dicValue: '停用'
            }],
            MERCHANT_TYPE: [{
                dicCode: '1',
                dicValue: '系统',
            }, {
                dicCode: '2',
                dicValue: '机构',
            }, {
                dicCode: '3',
                dicValue: '部门',
            }, ],
            MENU_TYPE: [{
                dicCode: '0',
                dicValue: '目录',
            }, {
                dicCode: '1',
                dicValue: '菜单',
            }, {
                dicCode: '2',
                dicValue: '按钮',
            }, ]
        }
        // return JSON.parse(sessionStorage.getItem('dicItems') || '[]')
    return dicItems
}


/**
 * 根据catalogId,获取字典集合，用于el-select,el-radio,el-checkbox UI组件
 */
export function getDicOption(catalogId) {
    var dicItems = getAllDicItems()
    return objToMap(dicItems).get(catalogId)
}


/**
 * 根据catalogId,字典标签获取字典值
 */
export function getDicValue(catalogId, originValue) {
    // debugger
    var dicItems = getAllDicItems()
    var data = objToMap(dicItems).get(catalogId)
    var value = ''
    if (data != null) {
        for (var i = 0; i < data.length; i++) {
            if (String(originValue) === data[i].dicCode) {
                value = data[i].dicValue
            }
        }
    }
    return value
}

/**
 *对象转换为Map
 */
function objToMap(obj) {
    let strMap = new Map()
    for (let k of Object.keys(obj)) {
        strMap.set(k, obj[k])
    }
    return strMap
}