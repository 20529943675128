export default {
    namespaced: true,
    state: {
        //权限信息 localStorage
        permissions: localStorage.getItem("edb-permissions") || ''
    },
    mutations: {
        changePermissions(state, payload) {
            state.permissions = payload;
            localStorage.setItem("edb-permissions", state.permissions)

        }
    }
}