import axios from "axios";
import { Message } from 'element-ui'
import router from "@/router"

const instance = axios.create({
    // baseURL: "/api",
    baseURL: "http://pt.medicalunion.cn/knowleage",
    timeout: 1000 * 120,
    // 代理的端口
    // proxy: {
    //     port: 8080,
    //     host: '127.0.0.1'
    // },
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    }
})

// 请求拦截器
instance.interceptors.request.use(config => {
    const accessToken = localStorage.getItem("edb-access-token")
        // const refresToken = localStorage.getItem("edb-refresh-token")
    if (accessToken && !config.url.endsWith('/login') && !config.url.endsWith('/captcha.jpg')) {
        config.headers["token"] = "bearer " + accessToken
    }
    return config
}, err => { return Promise.reject(err) })

//响应拦截器
instance.interceptors.response.use(res => {
    console.log('返回参数res:' + JSON.stringify(res))
        //外部网络判断
        // if (!res_data || res.status != 200 || res_data.code != '0') {
    if (!res || res.status !== 200) {
        // Message.error(statusText.msg || '网络请求错误');
        Message.error(res.statusText || '网络请求错误');
        return false
    }
    const res_data = res.data
    console.log("响应参数res_data:" + JSON.stringify(res_data))
        //内部数值判断
    if (!res_data || Number(res_data.code) !== 0) {
        // if (!res_data) {
        const codeRes = res_data.code
            // token过期，跳转到login
        if (codeRes === 401) {
            //token过期
            localStorage.removeItem("edb-authorization-token")
            router.push("/login")
        }
        Message.error(res_data.msg || '网络请求错误');
        return false
    }

    return res_data
}, err => { return Promise.reject(err) })

export default instance