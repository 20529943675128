import Vue from 'vue'
import Vuex from 'vuex'
import navCollapse from './navCollapse'
import userMenuData from './userMenuData'
import userInfo from './userInfo'
import userPermissions from './userPermissions'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        navCollapse,
        userMenuData,
        userInfo,
        userPermissions,
    }

})