import instance from "./request";

//获取验证码请求(函数式调用，每次发起会重新请求，赋值则引用时调用，无法重复请求)
// export const GetCatchaCodeApi = () => instance.get("/prod-api/captchaImage");
export const GetCatchaCodeApi = (params) => {
    const catchaCodeUrl = instance.defaults.baseURL + "/captcha.jpg?t=" + params.t
    return catchaCodeUrl
};

//登录
// 表单数据请求需加上application/x-www-form-urlencoded
// export const LoginApi = (params, config) => instance.post("/sys/login", params, config);
export const LoginApi = (params, config) => instance.post("/User/V2/UserLogin", params, config);

//获取用户可以访问的路由的api
export const GetUserRoutersApi = (params) => instance.post("/prod-api/getRouters", params);

//获取用户信息 todo:上线删除
export const GetUserInfoApi = () => instance.get("/sys/user/info");

//获取知识库
export const GetKnowleageInfo = (params) => instance.post("/Aigc/V2/GetKnowleageList", params);

//通过id查询知识库信息
export const GetKnowleageInfoApi = (params) => instance.post("/Aigc/V2/GetKnowleageInfo", params);

//获取分段列表

//获取文档列表 
export const GetDocumentsListApi = (params) => instance.post("/Aigc/V2/GetFileListInfo", params);

//获取分段列表 
export const GetSegmentsListApi = (params) => instance.post("/Aigc/V2/Segments/List", params);
export const DeleteSegmentsApi = (params) => instance.post("/Aigc/V2/Segments/Delete", params);
export const StatuSegmentsApi = (params) => instance.post("/Aigc/V2/Segments/Statu", params);
export const UpdateSegmentsApi = (params) => instance.post("/Aigc/V2/Segments/Update", params);
export const AddSegmentsApi = (params) => instance.post("/Aigc/V2/Segments/Add", params);


//获取文件类型
export const GetSupportType = () => instance.post("/Aigc/V2/GetSupportType");

//文件上传
export const UpdateFile = (params, configs) => instance.post("/Aigc/V2/FileUpLoad", params, configs);

//文件预览
export const SegmentedPreview = (params) => instance.post("/Aigc/V2/Segments/SegmentedPreview", params);


//空知识库创建
export const KnowledgeCreateApi = (params) => instance.post("/Aigc/V2/Knowledge/KnowledgeCreate", params);

// Embedding模型设置列表
export const QueryEmbeddingModels = () => instance.post("/Aigc/V2/Models/QueryEmbeddingModels");

//Rerank模型列表
export const QueryRerankModelsListApi = () => instance.post("/Aigc/V2/Models/QueryRerankModels");


//知识库创建
export const KnowledgeCreateSubmitApi = (params) => instance.post("/Aigc/V2/Knowledge/KnowledgeCreate", params);


export const AddFileApi = (params) => instance.post("/Aigc/V2/AddFile", params);

//编辑知识库
export const EditKnowleageInfoApi = (params) => instance.post("/Aigc/V2/EditKnowleageInfo", params);


// 删除知识库
export const KnowledgeDeleteApi = (params) => instance.post("/Aigc/V2/Knowledge/KnowledgeDelete", params);

// 文档列表
export const FileListInfoListApi = (params) => instance.post("/Aigc/V2/GetFileListInfo", params);

// 文档重命名
export const RenameFileNameApi = (params) => instance.post("/Aigc/V2/RenameFileName", params);



// 删除文档
export const DeleteFileApi = (params) => instance.post("/Aigc/V2/DeleteFile", params);
export const StatuFileApi = (params) => instance.post("/Aigc/V2/StatuFile", params);
//分段设置
export const SetFileApi = (params) => instance.post("/Aigc/V2/SetFile", params);
export const GetFileApi = (params) => instance.post("/Aigc/V2/GetFile", params);

// 召回测试
export const HitTestingApi = (params) => instance.post("/Aigc/V2/HitTesting", params);


//智能体
//获取列表
export const GetChatAssistantListApi = (params) => instance.post("/Aigc/V2/GetChatAssistantList", params);
export const GetTopChatAssistantListApi = (params) => instance.post("/Aigc/V2/GetTopChatAssistantList", params);
export const CreateChatAssistantApi = (params) => instance.post("/Aigc/V2/CreateChatAssistant", params);
export const EditChatAssistantApi = (params) => instance.post("/Aigc/V2/EditChatAssistant", params);
export const DeleteChatAssistantApi = (params) => instance.post("/Aigc/V2/DeleteChatAssistant", params);
export const ToppingChatAssistantApi = (params) => instance.post("/Aigc/V2/ToppingChatAssistant", params);
 
// 应用详情
export const QueryChatAssistantApi = (params) => instance.post("/Aigc/V2/QueryChatAssistant", params);

// 提示词生成
export const RuleGenerateApi = (params) => instance.post("/Aigc/V2/RuleGenerate", params);

// 获取默认模型
export const QueryDefaultModelsApi = (params) => instance.post("/Aigc/V2/Models/QueryDefaultModels", params);

// 获取LLM模型列表
export const QueryLLMModelsApi = () => instance.post("/Aigc/V2/Models/QueryLLMModels");

// 聊天助手参数保存
export const ChatAssistantSettingApi = (params) => instance.post("/Aigc/V2/ChatAssistant/ChatAssistantSetting", params);

// 用户权限查询
export const NavMenuApi = (params) => instance.post("/User/V2/NavMenu", params);

// 角色列表（角色名）
export const RoleApi = (params) => instance.post("/Aigc/V2/sys/role/list", params);

// 机构管理
export const GetMerchantListApi = (params) => instance.post("/Merchant/V2/GetMerchantList", params);

// 新增角色
export const roleRoleApi = (params) => instance.post("/Aigc/V2/sys/role/save", params);

export const GetTokenApi = (params) => instance.get("/Aigc/V2/GetToken", params);

// 获取角色信息
export const RoleInfoApi = (params) => instance.post("/Aigc/V2/sys/role/info", params);

// 删除角色
export const RoleDeleteApi = (params) => instance.post("/Aigc/V2/sys/role/delete", params);

// 保存角色
export const RoleSaveApi = (params) => instance.post("/Aigc/V2/sys/role/save", params);

// 更新角色
export const RoleUpdateApi = (params) => instance.post("/Aigc/V2/sys/role/update", params);

// 查询菜单
export const GetMenuListApi = (params) => instance.post("/Menu/V2/GetMenuList", params);

// 菜单信息
export const GetMenuInfoApi = (params) => instance.post("/Menu/V2/GetMenuInfo", params);

// 新建菜单
export const MenuSaveApi = (params) => instance.post("/Menu/V2/MenuSave", params);

// 机构信息
export const GetMerchantApi = (params) => instance.post("/Merchant/V2/GetMerchant", params);

// 新建机构
export const MerchantSaveApi = (params) => instance.post("/Merchant/V2/MerchantSave", params);

// 机构编辑
export const MerchantEditApi = (params) => instance.post("/Merchant/V2/MerchantEdit", params);

// 用户列表
export const userListApi = (params) => instance.post("/Aigc/V2/sys/user/list", params);

// 用户删除
export const userDelApi = (params) => instance.post("/Aigc/V2/sys/user/delete", params);

// 删除菜单
export const MenuDeleteApi = (params) => instance.post("/Menu/V2/MenuDelete", params);

//角色列表分类
export const roleSelectApi = (params) => instance.post("/Aigc/V2/sys/role/select", params);

//用户信息查询
export const userInfoApi = (params) => instance.post("/Aigc/V2/sys/user/info", params);

// 删除机构
export const MerchantDeletefoApi = (params) => instance.post("/Merchant/V2/MerchantDelete", params);

// 用户新增
export const userSaveoApi = (params) => instance.post("/Aigc/V2/sys/user/save", params);

// 用户修改
export const userUpdateApi = (params) => instance.post("/Aigc/V2/sys/user/update", params);

// 菜单编辑
export const MenuEditApi = (params) => instance.post("/Menu/V2/MenuEdit", params);

// 用户密码修改
export const updatePassApi = (params) => instance.post("/Aigc/V2/sys/user/updatePass", params);