export default {
    namespaced: true,
    state: {
        isCollapse: false
    },
    mutations: {
        // 状态取反
        chaneIsCollapse(state) {
            state.isCollapse = !state.isCollapse
        }
    }

}