// src/router/index.js
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import { NavMenuApi, GetKnowleageInfoApi } from '@/request/api'

// 告诉 Vue 使用 VueRouter
Vue.use(VueRouter)

const routes = [ // ...其他路由规则
    {
        path: "/login",
        name: 'login',
        component: () =>
            import ( /**" login" */ '../views/logion/UserLogin.vue')
    },
    {
        path: "*",
        component: () =>
            import ( /**" 404" */ '../views/Error_404.vue')
    }, {
        path: '/',
        name: 'mainlayout',

        component: () =>
            import ( /**" mainlayout" */ '../views/layout/MainLayout.vue'),
        redirect: "/home",
        children: [{
            path: "/home",
            component: () =>
                // import ( /**" HomeView" */ '../views/HomeView.vue'),
                import ( /**" HomeView" */ '../views/apps/AppListTop.vue'),
            meta: {
                titles: ['首页']
            }

        }, {
            path: "/jigou/jigou/info",
            component: () =>
                import ( /**" jigou" */ '../views/orgin/deptAdhocracy.vue'),
            meta: {
                titles: ['机构信息']
            }

        }, {
            path: "/GrProfile",
            meta: { titles: ["个人中心"] },
            component: () =>
                import ( /**" GrProfile" */ '../views/GrProfile.vue')
        }, {
            path: "/createKnow/:id?/:statue?",
            component: () =>
                import ( /**" HomeView" */ '../views/kbm/CreateKnowlegePanel.vue'),
            meta: {
                titles: ['创建知识库']
            }

        }, {
            path: "/recallTest/:id?",
            component: () =>
                import ( /**" HomeView" */ '../views/kbm/RecallTest.vue'),
            meta: {
                titles: ['召回测试']
            }

        }, {
            path: "/Segments/:dataset_id/:document_id",
            component: () =>
                import ( /**" HomeView" */ '../views/kbm/SegmentsPanel.vue'),
            meta: {
                titles: ['分段管理']
            }

        }, {
            path: "/SetSegment/:dataset_id?/:document_id?/:upload_file_id?",
            component: () =>
                import ( /**" HomeView" */ '../views/kbm/SetSegment.vue'),
            meta: {
                titles: ['分段设置']
            }

        }, {
            path: "/dm/:id?/:statue?",
            component: () =>
                import ( /**" HomeView" */ '../views/consumer/DocConsumer.vue'),
            meta: {
                titles: ['AI知识库管理', '知识库管理', '文档管理']
            },
            beforeEnter: async(to, from, next) => {
                const id = to.params.id
                if (id) {
                    // 查询知识库名称
                    const GetKnowleageInfoApiRes = await GetKnowleageInfoApi({
                        id: id
                    });
                    console.log('GetKnowleageInfoApiRes:', JSON.stringify(GetKnowleageInfoApiRes))
                    if (!GetKnowleageInfoApiRes && !GetKnowleageInfoApiRes.data) {
                        return;
                    }

                    console.log('to.meta.titles[to.meta.titles.length - 1]', to.meta.titles[to.meta.titles.length - 1])
                    to.meta.titles = ['AI知识库管理', '知识库管理', '文档管理', GetKnowleageInfoApiRes.data.name]
                }
                next();
            }

        }, {
            path: "/know/kbm",
            component: () =>
                import ( /**" HomeView" */ '../views/kbm/KnowledgePanel.vue'),
            meta: {
                titles: ['AI知识库管理', '知识库管理']
            }
        }, {
            path: "/agentManagement/apps/:mode",
            component: () =>
                import ( /**" HomeView" */ '../views/apps/AppList.vue'),
            meta: {
                titles: ['智能体管理', '聊天助手编排']
            }
        }, {
            path: "/agentManagement/apps2/:mode",
            component: () =>
                import ( /**" HomeView" */ '../views/apps/AppList2.vue'),
            meta: {
                titles: ['智能体管理', 'Agent应用管理']
            }
        }, {
            path: "/agentManagement/apps3/:mode",
            component: () =>
                import ( /**" HomeView" */ '../views/apps/AppList3.vue'),
            meta: {
                titles: ['智能体管理', '工作流管理']
            }
        }, {
            path: "/prat/:appid?/:type?",
            component: () =>
                // import ( /**" AIChat" */ '../views/agent/DifyAIChat.vue'),
                import ( /**" AIChat" */ '../views/agent/AIChat.vue'),
            meta: {
                titles: ['智能体管理', '聊天助手编排', '聊天助手基础编排']
            }
        }, {
            path: "/DifyCWFChat/:appid?",
            component: () =>
                // import ( /**" AIChat" */ '../views/agent/DifyAIChat.vue'),
                import ( /**" AIChat" */ '../views/agent/DifyCWFChat.vue'),
            meta: {
                titles: ['智能体管理', '聊天助手编排', '聊天助手工作流编排']
            }
        }, {
            path: "/DifyAIChat/:appid?",
            component: () =>
                // import ( /**" AIChat" */ '../views/agent/DifyAIChat.vue'),
                import ( /**" AIChat" */ '../views/agent/DifyAIChat.vue'),
            meta: {
                titles: ['智能体管理', '聊天助手编排', '聊天助手基础编排']
            }
        }, {
            path: "/DifyAgentChat/:appid?",
            component: () =>
                // import ( /**" AIChat" */ '../views/agent/DifyAIChat.vue'),
                import ( /**" AIChat" */ '../views/agent/DifyAgentChat.vue'),
            meta: {
                titles: ['智能体管理', 'Agent应用管理', 'Agent应用']
            }
        }, {
            path: "/DifyWFChat/:appid?",
            component: () =>
                // import ( /**" AIChat" */ '../views/agent/DifyAIChat.vue'),
                import ( /**" AIChat" */ '../views/agent/DifyWFChat.vue'),
            meta: {
                titles: ['智能体管理', '工作流管理', '工作流']
            }
        }, {
            path: "/prat1/:appid?/:type?",
            component: () =>
                // import ( /**" AIChat" */ '../views/agent/DifyAIChat.vue'),
                import ( /**" AIChat" */ '../views/agent/AIChat1.vue'),
            meta: {
                titles: ['聊天助手']
            }
        }, {
            path: "/prat2/:appid?/:type?",
            component: () =>
                // import ( /**" AIChat" */ '../views/agent/DifyAIChat.vue'),
                import ( /**" AIChat" */ '../views/agent/AIChat2.vue'),
            meta: {
                titles: ['Agent应用编排']
            }
        }, {
            path: "/DifyChat/:appid",
            component: () =>
                // import ( /**" AIChat" */ '../views/agent/DifyAIChat.vue'),
                import ( /**" AIChat" */ '../views/agent/DifyChat.vue'),
            meta: {
                titles: ['发布的应用']
            }
        }, {
            path: "/DifyWF/:appid",
            component: () =>
                // import ( /**" AIChat" */ '../views/agent/DifyAIChat.vue'),
                import ( /**" AIChat" */ '../views/agent/DifyWF.vue'),
            meta: {
                titles: ['发布的应用']
            }
        }, {
            path: "/xitong/role",
            component: () =>
                // import ( /**" AIChat" */ '../views/agent/DifyAIChat.vue'),
                import ( /**" AIChat" */ '../views/sys/userRole.vue'),
            meta: {
                titles: ['角色权限管理']
            }
        }, {
            path: "/xitong/meun",
            component: () =>
                // import ( /**" AIChat" */ '../views/agent/DifyAIChat.vue'),
                import ( /**" AIChat" */ '../views/sys/userMenu.vue'),
            meta: {
                titles: ['菜单管理']
            }
        }, {
            path: "/xitong/user",
            component: () =>
                // import ( /**" AIChat" */ '../views/agent/DifyAIChat.vue'),
                import ( /**" AIChat" */ '../views/sys/loginUser.vue'),
            meta: {
                titles: ['用户管理']
            }
        }, ],
    }
]

var router = new VueRouter({
    mode: 'history', // 使用 HTML5 History API
    base: process.env.BASE_URL,
    routes
})

// 路由守卫

// router.beforeEach((to, from, next) => {
//     if (to.path === from.path) {
//         next(false); // 取消导航
//     } else {
//         next(); // 继续导航
//     }
// })

router.beforeEach(async(to, from, next) => {

    // 判断是否是文档跳转，是则将当前路由title修改为文档名称
    // const toPath=to.path;
    // if(toPath&&toPath.includes()){

    // }

    // if (to.path === from.path) {
    //     next(false); // 取消导航
    // }
    // router.currentRoute.meta.titles = ['新标题'];
    // var documentName = to.params.documentName
    // console.log('documentName:', documentName)
    // if (documentName) {
    //     // 执行操作，例如修改标题
    //     // to.meta.titles[to.meta.titles.length - 1] = '新标题';
    //     to.meta.titles[to.meta.titles.length - 1] = documentName
    // }

    const accessToken = localStorage.getItem('edb-access-token')
    const refreshToken = localStorage.getItem('edb-refresh-token')
    const userInfo = store.state.userInfo
        // 1.访问login已登录，跳转到首页
    if (to.path === '/login' && accessToken && refreshToken) {
        next('/home');
        return
    }
    // 2.用户不是登录页面，但未登录，跳转到登录页面
    if (to.path !== '/login' && (!accessToken || !refreshToken)) {
        next('/login')
        return
    }

    // 有token，初始数据为0，则加载菜单
    if (userInfo && accessToken && refreshToken && store.state.userMenuData.menuData.length == 0) {
        let NavMenuApiRes = await NavMenuApi({ loginCode: userInfo.userInfo.loginCode })
        if (!NavMenuApiRes || !NavMenuApiRes.data) return;
        NavMenuApiRes = NavMenuApiRes.data
            //拼装 menuData
        let newUserMenuData = [{ title: "首页", path: "/", icon: "shouye" }]
        let ret = NavMenuApiRes.menuList.map(item => {
            if (item.children) {
                return {
                    title: item.title,
                    icon: item.icon,
                    path: item.url,
                    children: item.children.map(sitem => {
                        return {
                            title: sitem.title,
                            icon: sitem.icon,
                            path: item.url + "/" + sitem.url
                        }
                    })
                }
            } else {
                return {
                    title: item.title,
                    path: item.url,
                    icon: item.icon
                }
            }

        })
        newUserMenuData = [...newUserMenuData, ...ret];

        //更新数据
        store.commit("userMenuData/changeMenuData", newUserMenuData);
        // 权限
        store.commit("userPermissions/changePermissions", NavMenuApiRes.permissions.join(','))

        console.log('-----菜单数据----:' + JSON.stringify(newUserMenuData))

        //以上生成菜单数据

        //以下生成路由数据
        let newChildrenRoutes = [{
                path: "/home",
                meta: { titles: ["首页"] },
                component: () =>
                    import ('../views/HomeView.vue')
            },
            {
                path: "/profile",
                meta: { titles: ["个人中心"] },
                component: () =>
                    import ('../views/GrProfile.vue')
            }
        ]
        NavMenuApiRes.menuList.forEach(item => {
            let ret = item.children.map(sitem => {
                return {
                    path: item.url + "/" + sitem.url,
                    component: () =>
                        // import (`../views${item.url}/${sitem.name}.vue`),
                        import (`../views${item.url}/${sitem.url}.vue`),
                    meta: { titles: [item.title, sitem.title] }
                }
            })
            newChildrenRoutes = [...newChildrenRoutes, ...ret]
        });
        newChildrenRoutes.forEach(item => {
            router.addRoute('mainlayout', item);
        })
        console.log('路由数据----:', JSON.stringify(newChildrenRoutes))
            // 重新走一遍,因为第一次走，路由还没完成，还是空的，需要再走一遍
        next(to.path);
        return

    }
    //放行
    next()
})
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function(location) {
    return originalPush.call(this, location).catch(err => {
        console.log('err:' + err)
    })
};

export default router